<template>
  <div>
    <div class="road">
      <div class="dash" />
    </div>
    <div class="steps">
      <template v-for="step in steps">
        <vehicle-tracker-step
          :key="step.title"
          :active="step.active"
          :blocking="step.blocking"
          :right="step.right"
          :icon="step.icon"
          :title="step.title"
          :quip="step.quip"
          :blurb="step.blurb"
          :description="step.description"
          @iconClick="$emit('iconClick')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import VehicleTrackerStep from '@/components/Vehicles/VehicleTrackerStep.vue'
import { VEHICLE_FILINGS, VEHICLE_STATUSES } from '@/common/modules/constants'
export default {
  name: 'VehicleTracker',
  components: {
    VehicleTrackerStep,
  },
  props: {
    status: String,
    filingName: String,
    estimate: String,
  },
  computed: {
    steps() {
      let displaySteps = []

      if ([VEHICLE_FILINGS.VEHICLE_REGISTRATION].includes(this.filingName)) {
        displaySteps.push(this.awaitingDocuments)
        displaySteps.push(this.documentsReceived)
      } else {
        displaySteps.push(this.signupReceived)
      }

      if (this.status === VEHICLE_STATUSES.NOT_READY_TO_FILE) {
        displaySteps.push(this.informationNeeded)
      }
      if (this.status !== VEHICLE_STATUSES.NOT_READY_TO_FILE && this.status !== VEHICLE_STATUSES.HELD) {
        displaySteps.push(this.submitted)
      }
      if (this.status === VEHICLE_STATUSES.HELD) {
        displaySteps.push(this.rejected)
      }

      if ([VEHICLE_FILINGS.VEHICLE_REGISTRATION, VEHICLE_FILINGS.VEHICLE_ORGANIZATIONAL_PLATES, VEHICLE_FILINGS.VEHICLE_RENEWAL].includes(this.filingName)) {
        if (this.status === VEHICLE_STATUSES.READY_TO_MAIL) {
          displaySteps.push(this.readyToMail)
        } else {
          displaySteps.push(this.verifyAddress)
        }
      }

      if ([VEHICLE_FILINGS.VEHICLE_TEMPORARY_PLATES].includes(this.filingName)) {
        displaySteps.push(this.tempPlatesComplete)
      } else {
        displaySteps.push(this.inTransit)
      }

      return displaySteps
    },
    awaitingDocuments() {
      return {
        active: this.status === VEHICLE_STATUSES.NEW || this.status === VEHICLE_STATUSES.AWAITING_CLIENT_INPUT,
        icon: 'tracker/envelope-wait',
        title: 'Awaiting Documents',
        quip: `You're on the right track!`,
        blurb: 'Awaiting your documents',
        description: 'We have your signup! We’re now awaiting the receipt of your vehicle paperwork in our office.',
      }
    },
    signupReceived() {
      return {
        active: this.status === VEHICLE_STATUSES.NEW || this.status === VEHICLE_STATUSES.AWAITING_CLIENT_INPUT,
        icon: 'tracker/vehicle-check',
        title: 'Received',
        quip: `You're on the right track!`,
        blurb: 'We have received your request',
        description: 'Thank you for requesting vehicle services! We\'ll keep you informed with updates throughout the process.',
      }
    },
    documentsReceived() {
      return {
        active: this.status === VEHICLE_STATUSES.VEHICLE_DOCUMENTS_RECEIVED,
        icon: 'tracker/vehicle-paper',
        title: 'Documents Received',
        quip: `You're on the right track!`,
        blurb: 'We have received your documents',
        description: 'Thank you for submitting your documents. We have received them and will begin processing them shortly.',
      }
    },
    informationNeeded() {
      return {
        active: this.status === VEHICLE_STATUSES.NOT_READY_TO_FILE,
        blocking: true,
        icon: 'tracker/vehicle-cone',
        title: 'Information Needed',
        quip: 'Oops! We hit a roadblock',
        blurb: 'Waiting on additional information',
        description: `We need you to complete the required documents before we can continue processing your order. <br /> <br /> Please click the traffic cone to view and complete the required documents`,
      }
    },
    submitted() {
      return {
        active: this.status === VEHICLE_STATUSES.SUBMITTED_TO_STATE || this.status === VEHICLE_STATUSES.IN_PROGRESS,
        icon: 'tracker/vehicle-wait',
        title: 'Submitted To State',
        quip: `You're on the right track!`,
        blurb: 'Your documents have been submitted successfully',
        description: `Your filing has been submitted to the state and is currently being processed, which is estimated to take approximately ${this.estimate}. Once we receive the documents back from the state, we will ship them to the address on file in your account.`,
      }
    },
    rejected() {
      return {
        active: this.status === VEHICLE_STATUSES.HELD,
        blocking: true,
        icon: 'tracker/vehicle-cone',
        title: 'Rejected By State',
        quip: 'Oops! We hit a roadblock',
        blurb: 'Rejected by state',
        description: `We need you to complete the required documents before we can continue processing your order. <br /> <br /> Please click the traffic cone to view and complete the required documents`,
      }
    },
    verifyAddress() {
      return {
        active: this.status === VEHICLE_STATUSES.WAITING_ON_ADDRESS,
        right: true,
        icon: 'tracker/vehicle-wait-paper',
        title: 'Verify Address',
        quip: `You're on the right track!`,
        blurb: 'We have your vehicle documents back from the state!',
        description: 'We have received your vehicle documents in office and need to confirm your mailing address before shipment.',
      }
    },
    readyToMail() {
      return {
        active: this.status === VEHICLE_STATUSES.READY_TO_MAIL,
        right: true,
        icon: 'tracker/vehicle-wait',
        title: 'Ready To Mail',
        quip: `You're on the right track!`,
        blurb: 'We have your mailing address!',
        description: 'We have your mailing address and will ship your documents soon.',
      }
    },
    inTransit() {
      return {
        active: this.status === VEHICLE_STATUSES.COMPLETED,
        right: true,
        icon: 'tracker/vehicle-star',
        title: 'Shipped',
        quip: `You're on the right track!`,
        blurb: 'Your documents have shipped!',
        description: 'Your vehicle documents have been shipped! Be sure to check your mail for their arrival.',
      }
    },
    tempPlatesComplete() {
      return {
        active: this.status === VEHICLE_STATUSES.COMPLETED,
        right: true,
        icon: 'tracker/vehicle-star',
        title: 'Complete',
        quip: `You're on the right track!`,
        blurb: 'Your temporary plates are ready',
        description: `Your temporary plates are ready for you to download. View them in your <a href="#/documents">documents</a>.`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$road: #e6e6ed;
$road-dash: #cccddd;
$dash-height: 3px;

@media (max-width: 735px) {
  .steps {
    margin: 3rem 0;
    padding-right: 1rem;
  }
}

@media (min-width: 736px) {
  .road {
    position: relative;
    background: $road;
    border-radius: 1.25rem;
    height: 2.5rem;
    margin: 2rem 3rem 0 3rem;

    .dash {
      position: absolute;
      top: calc(50% - $dash-height / 2);
      left: 1rem;
      right: 1rem;
      height: $dash-height;
      background-image: linear-gradient(to right, $road-dash 50%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 2rem $dash-height;
      background-repeat: repeat-x;
    }
  }

  .steps {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 4rem 1rem 4rem;
  }
}
</style>
