<template>
  <div
    class="step"
    :class="{ active: active }"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <div @click="$emit('iconClick')">
      <div class="icon">
        <b-img :src="`images/${icon}.svg`" />
      </div>
      {{ title }}
    </div>
    <div class="details-popup" :class="{ right: right }">
      <h5 :class="{ oops: blocking }">{{ quip }}</h5>
      <h4>{{ blurb }}</h4>
      <p v-html="description" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehicleTrackerStep',
  props: {
    active: Boolean,
    blocking: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    icon: String,
    title: String,
    quip: String,
    blurb: String,
    description: String,
  },
  methods: {
    mouseenter(event) {
      event.target.querySelector('.details-popup').classList.add('show')
    },
    mouseleave(event) {
      event.target.querySelector('.details-popup').classList.remove('show')
    },
  },
}
</script>

<style lang="scss" scoped>
$primary: #283d96;
$primary-dark: darken($primary, 10%);
$teal: #5dcac6;
$oops: #e85834;
$font: #212529;
$muted-font: #494949;
$road: #e6e6ed;
$road-dash: #cccddd;
$line-width: 3px;

.oops {
  color: $oops !important;
}

@media (max-width: 735px) {
  .step {
    position: relative;
    border-left: $line-width solid $road;
    margin-left: 2.875rem;
    padding-left: 3.75rem;
    padding-top: 1.5rem;
    min-height: 6rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: $road;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: none;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: $line-width;
        height: 1.5rem;
        background-color: $road;
      }

      .icon {
        left: calc(-1.55rem + $line-width)
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -1.55rem;
      width: 3rem;
      height: 3rem;
      border: $line-width solid $road;
      border-radius: 3rem;
      background: $road;

      &:after {
        content: "";
        background-image: url("/images/tracker/icon-completed-inactive.svg");
        min-width: 3rem;
        height: 3rem;
      }

      img {
        display: none;
        max-width: 3rem;
        max-height: 3rem;
      }
    }

    .details-popup {
      display: none;
    }
  }

  .step.active {
    color: $teal;

    & ~ .step .icon {
      background: white;

      &:after {
        display: none;
      }
    }

    .icon {
      background: none;
      border: none;

      &:after {
        display: none;
      }

      img {
        display: block;
      }
    }

    .details-popup {
      display: block;

      h5 {
        display: none;
      }

      h4 {
        font-size: 1.5rem;
        font-weight: 400;
        color: $font;
        margin: 0.5rem 0 1rem 0;
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: $font;
        margin: 0;
      }
    }
  }
}

@media (min-width: 736px) {
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: $muted-font;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    width: 5rem;
    min-height: 7.25rem;
    padding-top: 1rem;
    padding-bottom: 3.5rem;

    .icon {
      display: none;
    }

    .details-popup {
      display: none;
    }
  }

  .step.active {
    color: $teal;

    .icon {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 5rem;
      height: 5rem;
      position: absolute;
      top: -5rem;
      padding-bottom: 0.25rem;

      img {
        max-height: 4rem;
        max-width: 5rem;
      }
    }

    .details-popup.show {
      display: block;
      position: absolute;
      top: 5.5rem;
      z-index: 99999;
      border: 3px solid $primary-dark;
      background: white;
      padding: 2rem;
      text-align: left;
      width: 23rem;
      left: -1.75rem;
      color: $font;

      &:before {
        content: "";
        box-shadow: -3px -3px 0 0 $primary-dark;
        background: white;
        transform: rotate(45deg);
        position: absolute;
        top: -15px;
        left: 3rem;
        width: 2rem;
        height: 2rem;
      }

      h5 {
        font-size: 0.825rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $teal;
        margin: 0;
      }

      h4 {
        font-size: 1.75rem;
        font-weight: 400;
        margin: 0.5rem 0 1rem 0;
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0;
      }

      &.right {
        left: unset;
        right: -1.75rem;

        &:before {
          left: unset;
          right: 3rem;
        }
      }
    }
  }
}
</style>
